// src/components/Footer.js
import React from 'react';
import { Nav, Container, Row, Col } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Flag from 'react-world-flags';
import LanguageSwitcher from './LanguageSwitcher';
import { faFacebook, faTwitter, faYoutube, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';

const DownloadApp = () => {
    const { lang } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation(); // Get the current path




    return (


        <Container className="p-4">
            <Row>

                <Col md={12} className="p-4">


                    <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                    <div className="d-flex justify-content-center">
                        <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                            <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                        </a>
                        <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                            <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                        </a>
                    </div>
                    <div className="d-flex justify-content-center">
                        {/* <small> {t('rated')} </small> */}
                        <div>
                            <FontAwesomeIcon icon={faStar} color="gold" />
                            <FontAwesomeIcon icon={faStar} color="gold" />
                            <FontAwesomeIcon icon={faStar} color="gold" />
                            <FontAwesomeIcon icon={faStar} color="gold" />
                            <FontAwesomeIcon icon={faStar} color="gold" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <img style={{ width: '130px', height: 'auto', marginRight: '10px' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                        <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                    </div>
                    <p className="text-center mt-3 d-none d-md-block">{t('Scan the QR codes below for quick access:')}</p>


                    {/* Social Sharing Buttons */}
                    {/* <div className="social-sharing text-center my-4">
                        <p>{t('global.share_p')}</p>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.petshome.app/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} size="2x" className="mx-2" />
                        </a>
                        <a href="https://twitter.com/intent/tweet?url=https://www.petshome.app/&text=Sell%20Your%20Pet%20Online%20for%20Free%20with%20Pets%20Home%20App" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} size="2x" className="mx-2" />
                        </a>
                        <a href="https://www.instagram.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2x" className="mx-2" />
                        </a>
                        <a href="https://www.pinterest.com/petshomeapp/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faPinterest} size="2x" className="mx-2" />
                        </a>
                        <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} size="2x" className="mx-2" />
                        </a>
                    </div> */}
                </Col>
            </Row>
        </Container>


    );
};

export default DownloadApp;
