// src/pages/CreateAdvert.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'; // Import Navigate
import { Helmet } from 'react-helmet-async';
import {
    Container,
    Form,
    Button,
    Col,
    Row,
    Alert,
    Card,
    ProgressBar
} from 'react-bootstrap';
import {
    collection,
    doc,
    updateDoc,
    addDoc,
    query,
    where,
    onSnapshot,
    deleteDoc
} from 'firebase/firestore';
import { storage, db } from '../firebase'; // Ensure these are correctly exported from your Firebase config
import { pet_types } from '../data/pet_types'; // Your pet types & breeds data
import { UserAuth } from '../context/AuthContext';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import LoginComponent from '../components/LoginComponent';
import DownloadApp from '../components/DownloadApp';
import AdvertCarousel from '../components/AdvertCarousel';
import { t } from 'i18next';


function CreateAdvert() {
    const { user, userData } = UserAuth();

    // -------------------------------
    // LOCATION CHECK
    // -------------------------------
    const [locationAllowed, setLocationAllowed] = useState(false);

    useEffect(() => {
        // Check if userData has a valid location
        if (
            userData &&
            userData.location &&
            userData.location.coordinates &&
            Array.isArray(userData.location.coordinates) &&
            userData.location.coordinates.length === 2 &&
            userData.location.coordinates[0] &&
            userData.location.coordinates[1]
        ) {
            setLocationAllowed(true);
        } else {
            setLocationAllowed(false);
        }
    }, [userData]);

    const handleRequestLocation = () => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser.');
            return;
        }
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords;
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    await updateDoc(userDocRef, {
                        location: {
                            type: 'Point',
                            coordinates: [latitude, longitude]
                        }
                    });
                    setLocationAllowed(true);
                } catch (error) {
                    console.error('Error updating location:', error);
                }
            },
            (error) => {
                console.error('Error getting location:', error);
                alert('Please allow location access.');
            }
        );
    };

    // -------------------------------
    // STEP & FORM STATES
    // -------------------------------
    // step: 1 = basic advert info, 2 = image upload, 3 = ad under review
    const [step, setStep] = useState(1);

    // Basic advert details
    const currentUtcTimestamp = Math.floor(Date.now() / 1000);
    const [advertData, setAdvertData] = useState({
        advertStatus: 0,
        title: '',
        description: '',
        advert_type: 'For Sale', // hardcoded
        pet_type: '',
        pet_breed: '',
        pet_age: '', // optional number
        asking_price: '',
        create_date: new Date().toISOString(),
        utc: currentUtcTimestamp,
        images: {
            main_image: '',
            image_1: '',
            image_2: ''
        },
        source: "web"
    });

    // Image files & upload progress for step 2
    const [imageFiles, setImageFiles] = useState({
        main_image: null,
        image_1: null,
        image_2: null
    });
    const [uploadProgress, setUploadProgress] = useState({
        main_image: 0,
        image_1: 0,
        image_2: 0
    });

    // Firestore document ID for the advert (after creation)
    const [currentAdvertDocId, setCurrentAdvertDocId] = useState(null);

    // Error message
    const [errorMessage, setErrorMessage] = useState('');

    // -------------------------------
    // PET TYPES & BREEDS (Right Column Form)
    // -------------------------------
    const [petTypes, setPetTypes] = useState([]);
    const [breedTypes, setBreedTypes] = useState([]);

    useEffect(() => {
        setPetTypes(pet_types.map((type) => type.name));
    }, []);

    useEffect(() => {
        if (advertData.pet_type) {
            const selected = pet_types.find((type) => type.name === advertData.pet_type);
            if (selected) {
                setBreedTypes(selected.breeds);
            } else {
                setBreedTypes([]);
            }
        } else {
            setBreedTypes([]);
        }
    }, [advertData.pet_type]);

    // -------------------------------
    // HANDLE FORM INPUT CHANGES (Step 1)
    // -------------------------------
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdvertData({ ...advertData, [name]: value });
    };

    // Validate text input by checking minimum word count
    const isValidTextInput = (text, minWords) => {
        const words = text.trim().split(/\s+/);
        return words.length >= minWords;
    };

    // -------------------------------
    // STEP 1: SUBMIT BASIC ADVERT INFO
    // -------------------------------
    const handleSubmitStep1 = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Ensure location is allowed
        if (!locationAllowed) {
            setErrorMessage('Location is required. Please allow location access.');
            return;
        }

        // Basic validations


        if (!isValidTextInput(advertData.title, 3)) {
            setErrorMessage('Title must contain at least 3 words.');
            return;
        }
        if (!isValidTextInput(advertData.description, 5)) {
            setErrorMessage('Description must contain at least 5 words.');
            return;
        }
        if (!advertData.asking_price) {
            setErrorMessage('Please enter the asking price.');
            return;
        }
        if (!advertData.pet_type) {
            setErrorMessage('Please select a pet type.');
            return;
        }
        if (!advertData.pet_breed) {
            setErrorMessage('Please select a pet breed.');
            return;
        }
        if (!user) {
            setErrorMessage('Session expired.');
            return;
        }

        try {
            // Augment advert data with user info
            const updatedData = {
                ...advertData,
                author_Id: user.uid,
                country_code: userData.country_code || '',
                country_name: userData.country_name || '',
                location: userData.location || null  // Save the location value here
            };
            const collectionRef = collection(db, 'adverts');
            const advertRef = await addDoc(collectionRef, updatedData);
            setCurrentAdvertDocId(advertRef.id);
            setStep(2);
        } catch (error) {
            console.error('Error creating advert:', error);
            setErrorMessage('Error creating advert.');
        }
    };

    // -------------------------------
    // STEP 2: IMAGE UPLOAD HANDLING
    // -------------------------------
    const handleImageUpload = async (imageField) => {
        if (!currentAdvertDocId) {
            console.error('Advert document ID is not available');
            return;
        }
        const file = imageFiles[imageField];
        if (!file) {
            console.error(`No file selected for ${imageField}`);
            return;
        }
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        if (file.size > maxSizeInBytes) {
            console.error(`Image size exceeds ${maxSizeInBytes} bytes`);
            return;
        }

        const maxWidth = 400;
        const maxHeight = 400;
        const image = new Image();
        const reader = new FileReader();

        reader.onload = (e) => {
            image.src = e.target.result;
            image.onload = async () => {
                const canvas = document.createElement('canvas');
                let width = image.width;
                let height = image.height;
                if (width > maxWidth || height > maxHeight) {
                    const ratio = Math.min(maxWidth / width, maxHeight / height);
                    width = width * ratio;
                    height = height * ratio;
                }
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0, width, height);

                canvas.toBlob(async (blob) => {
                    try {
                        const storageRef = ref(storage, `petshome/adverts/${currentAdvertDocId}/${imageField}.jpg`);
                        const uploadTask = uploadBytesResumable(storageRef, blob);

                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                setUploadProgress((prev) => ({ ...prev, [imageField]: progress }));
                            },
                            (error) => {
                                console.error(error);
                            },
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                    // Update Firestore document with the image URL
                                    const advertDocRef = doc(db, 'adverts', currentAdvertDocId);
                                    updateDoc(advertDocRef, {
                                        [`images.${imageField}`]: downloadURL
                                    }).then(() => {
                                        setAdvertData((prev) => ({
                                            ...prev,
                                            images: {
                                                ...prev.images,
                                                [imageField]: downloadURL
                                            }
                                        }));
                                    });
                                });
                            }
                        );
                    } catch (error) {
                        console.error(`Error uploading ${imageField}:`, error);
                    }
                }, 'image/jpeg', 0.9);
            };
        };
        reader.readAsDataURL(file);
    };

    const handleSubmitImages = async (e) => {
        e.preventDefault();
        if (imageFiles.main_image) await handleImageUpload('main_image');
        if (imageFiles.image_1) await handleImageUpload('image_1');
        if (imageFiles.image_2) await handleImageUpload('image_2');
        // After images are uploaded, move to "under review" state
        setStep(3);
    };

    // -------------------------------
    // RESET FORM (Create New Ad)
    // -------------------------------
    const handleResetForm = () => {
        setStep(1);
        setCurrentAdvertDocId(null);
        setAdvertData({
            advertStatus: 0,
            title: '',
            description: '',
            advert_type: 'For Sale',
            pet_type: '',
            pet_breed: '',
            pet_age: '',
            asking_price: '',
            create_date: new Date().toISOString(),
            utc: Math.floor(Date.now() / 1000),
            images: {
                main_image: '',
                image_1: '',
                image_2: ''
            }
        });
        setImageFiles({
            main_image: null,
            image_1: null,
            image_2: null
        });
        setUploadProgress({
            main_image: 0,
            image_1: 0,
            image_2: 0
        });
        setErrorMessage('');
    };

    // -------------------------------
    // LEFT COLUMN: USER ADVERTS LIST WITH INLINE EDITING
    // -------------------------------
    const [userAdverts, setUserAdverts] = useState([]);
    const [editingAdvertId, setEditingAdvertId] = useState(null);
    const [editingAdvertData, setEditingAdvertData] = useState({
        title: '',
        description: '',
        asking_price: '',
        pet_age: ''
    });

    useEffect(() => {
        if (user) {
            const q = query(
                collection(db, 'adverts'),
                where('author_Id', '==', user.uid),
                where('advertStatus', 'in', [0, 1])
            );
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const adverts = [];
                querySnapshot.forEach((docSnap) => {
                    adverts.push({ id: docSnap.id, ...docSnap.data() });
                });
                setUserAdverts(adverts);
            });
            return () => unsubscribe();
        }
    }, [user]);


    const deleteAdvert = async (advertId) => {
        try {
            const advertDocRef = doc(db, 'adverts', advertId);
            await updateDoc(advertDocRef, { advertStatus: 2 });
        } catch (error) {
            console.error('Error updating advert status to deleted:', error);
        }
    };


    const startEditing = (advert) => {
        setEditingAdvertId(advert.id);
        setEditingAdvertData({
            title: advert.title,
            description: advert.description,
            asking_price: advert.asking_price,
            pet_age: advert.pet_age || ''
        });
    };

    const cancelEditing = () => {
        setEditingAdvertId(null);
        setEditingAdvertData({
            title: '',
            description: '',
            asking_price: '',
            pet_age: ''
        });
    };

    const saveEditing = async (advertId) => {
        try {
            const advertDocRef = doc(db, 'adverts', advertId);
            await updateDoc(advertDocRef, {
                title: editingAdvertData.title,
                description: editingAdvertData.description,
                asking_price: editingAdvertData.asking_price,
                pet_age: editingAdvertData.pet_age
            });
            cancelEditing();
        } catch (error) {
            console.error('Error updating advert:', error);
        }
    };

    // -------------------------------
    // RENDER
    // -------------------------------


    // Now, after all hooks are declared, you can conditionally render if the user is not logged in.
    if (!user) {
        // Either redirect or display the LoginComponent.
        return (
            <>
                <Helmet>
                    <title>{t("rehome_sell.meta_title")}</title>
                    <meta name="description" content={t('rehome_sell.meta_description')} />
                    <meta name="keywords" content={t('rehome_sell.meta_keywords')} />
                </Helmet>
                <Container className="py-4">
                    {/* Hero Section */}
                    <Row className="mb-5 align-items-center">
                        <Col md={7} className="p-4">
                            <h1 className="mb-3">{t("rehome_sell.h1")}</h1>
                            <p className="lead mb-4">{t("rehome_sell.subheading")}</p>

                            <div className="intro-section mb-4">
                                <h2 className="h3">{t("rehome_sell.intro_section.title")}</h2>
                                <p>{t("rehome_sell.intro_section.content")}</p>
                                <div className="stats-bar bg-light p-3 rounded my-3 text-center">
                                    <small className="text-muted">{t("rehome_sell.intro_section.stats")}</small>
                                </div>
                                {/* <Button variant="primary" size="lg" className="mt-2 d-block d-md-inline-block">
                                    {t("rehome_sell.intro_section.cta_button")}
                                </Button> */}

                                <DownloadApp></DownloadApp>
                            </div>
                        </Col>
                        <Col md={5} className="p-4 text-center">
                            <img
                                loading="lazy"
                                src="/assets/web/freepetads.jpg"
                                alt="Sell or rehome your pet online"
                                className="img-fluid rounded shadow-lg"
                                style={{ maxWidth: '350px' }}
                            />
                            {/* <div className="mt-4">
                                <LoginComponent />
                            </div> */}
                        </Col>
                    </Row>

                    {/* How It Works Section */}
                    <Row className="mb-5 bg-light rounded p-4">
                        <Col xs={12}>
                            <h2 className="text-center mb-4">{t("rehome_sell.how_it_works.title")}</h2>
                            <Row className="mt-4">
                                {[0, 1, 2].map((index) => (
                                    <Col md={4} key={index} className="mb-4">
                                        <div className="step-card text-center p-3 h-100 border rounded bg-white">
                                            <div className="step-number mb-3">
                                                <span className="badge bg-primary rounded-circle p-3">
                                                    {t(`rehome_sell.how_it_works.steps.${index}.step`)}
                                                </span>
                                            </div>
                                            <h3 className="h5 mb-3">{t(`rehome_sell.how_it_works.steps.${index}.title`)}</h3>
                                            <p>{t(`rehome_sell.how_it_works.steps.${index}.content`)}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            {/* <div className="text-center mt-4">
                                <Button variant="success" size="lg">
                                    {t("rehome_sell.how_it_works.app_download_prompt")}
                                </Button>
                            </div> */}
                        </Col>
                    </Row>

                    {/* Why Choose Section */}
                    <Row className="mb-5">
                        <Col xs={12}>
                            <h2 className="text-center mb-4">{t("rehome_sell.why_choose_section.title")}</h2>
                            <Row className="mt-4">
                                {[0, 1, 2, 3, 4].map((index) => (
                                    <Col md={6} lg={4} key={index} className="mb-4">
                                        <div className="benefit-card p-3 h-100 border rounded">
                                            <div className="benefit-icon mb-3 text-primary">
                                                <i className="fa fa-check-circle fa-2x"></i>
                                            </div>
                                            <h3 className="h5 mb-2">{t(`rehome_sell.why_choose_section.benefits.${index}.title`)}</h3>
                                            <p>{t(`rehome_sell.why_choose_section.benefits.${index}.content`)}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>

                    {/* Testimonials Section */}
                    {/* <Row className="mb-5 bg-light rounded p-4">
                        <Col xs={12}>
                            <h2 className="text-center mb-4">{t("rehome_sell.testimonials.title")}</h2>
                            <Row className="mt-4">
                                {[0, 1, 2].map((index) => (
                                    <Col md={4} key={index} className="mb-4">
                                        <div className="testimonial-card p-4 h-100 border rounded bg-white">
                                            <p className="font-italic mb-3">"{t(`rehome_sell.testimonials.stories.${index}.quote`)}"</p>
                                            <div className="testimonial-footer">
                                                <p className="mb-0">
                                                    <strong>{t(`rehome_sell.testimonials.stories.${index}.name`)}</strong> - {t(`rehome_sell.testimonials.stories.${index}.pet`)}
                                                </p>
                                                <small className="text-muted">{t(`rehome_sell.testimonials.stories.${index}.location`)}</small>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row> */}

                    {/* FAQ Section - Simple version without Accordion */}
                    <Row className="mb-5">
                        <Col xs={12}>
                            <h2 className="text-center mb-4">{t("rehome_sell.faq_section.title")}</h2>
                            {[0, 1, 2, 3, 4].map((index) => (
                                <div key={index} className="faq-item mb-4 border-bottom pb-3">
                                    <h3 className="h5 mb-3">{t(`rehome_sell.faq_section.faqs.${index}.question`)}</h3>
                                    <p>{t(`rehome_sell.faq_section.faqs.${index}.answer`)}</p>
                                </div>
                            ))}
                        </Col>
                    </Row>

                    {/* App Benefits */}
                    <Row className="mb-5 bg-light rounded p-4">
                        <Col xs={12}>
                            <h2 className="text-center mb-4">{t("rehome_sell.app_benefits.title")}</h2>
                            <Row className="mt-4">
                                {[0, 1, 2, 3, 4].map((index) => (
                                    <Col md={6} lg={4} key={index} className="mb-4">
                                        <div className="feature-card text-center p-3 h-100 border rounded bg-white">
                                            <div className="feature-icon mb-3 text-primary">
                                                <i className="fa fa-star fa-2x"></i>
                                            </div>
                                            <h3 className="h5 mb-2">{t(`rehome_sell.app_benefits.features.${index}.title`)}</h3>
                                            <p>{t(`rehome_sell.app_benefits.features.${index}.description`)}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            {/* <div className="text-center mt-4">
                                <Button variant="primary" size="lg">
                                    {t("rehome_sell.app_benefits.download_prompt")}
                                </Button>
                            </div> */}
                        </Col>
                    </Row>

                    {/* Popular Searches */}
                    <Row className="mb-5">
                        <Col xs={12}>
                            <div className="border rounded p-4">
                                <h2 className="h4 mb-3">{t("rehome_sell.popular_searches.title")}</h2>
                                <p>{t("rehome_sell.popular_searches.content")}</p>
                            </div>
                        </Col>
                    </Row>

                    {/* Closing CTA */}

                </Container>
            </>
        );
        // Alternatively, you can use <Navigate to="/login" replace /> if you have a login route.
    }


    return (
        <Container className="mt-4">
            {!locationAllowed && (
                <Alert variant="warning">
                    {t("You must allow location access in order to create an ad.")}
                    <Button variant="outline-warning" size="sm" onClick={handleRequestLocation} className="ms-2">
                        {t("Allow Location")}
                    </Button>
                </Alert>
            )}
            <Row>
                {/* Left Column: User Adverts List with Inline Editing */}
                <Col md={8}>
                    <div className="container rounded bg-light p-2 mb-2">
                        {/* <h3 className="text-center mb-4">My Pet Ads</h3> */}
                        <p className="text-center mb-4">
                            {t("Manage your pet ads and monitor their performance with ease. For real-time statistics, instant notifications, and direct chat with potential adopters, please download our mobile app. Stay connected on the go and get all the updates you need right at your fingertips.")}
                        </p>

                        <p className="text-center">
                            <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer">Download Pets Home App for Android</a> | <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer">Download Pets Home App for iOS</a>
                        </p>

                    </div>

                    {userAdverts.length === 0 && <p>{t("Create Rehome / Sell Ad")}</p>}
                    <Row>
                        {userAdverts.map((advert) => (
                            <Col key={advert.id} lg={4} className="mb-3">
                                <Card>
                                    <AdvertCarousel advert={advert} />

                                    <Card.Body>
                                        {editingAdvertId === advert.id ? (
                                            <>
                                                <Form.Group className="mb-2">
                                                    <Form.Control
                                                        type="text"
                                                        value={editingAdvertData.title}
                                                        onChange={(e) =>
                                                            setEditingAdvertData((prev) => ({ ...prev, title: e.target.value }))
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={2}
                                                        value={editingAdvertData.description}
                                                        onChange={(e) =>
                                                            setEditingAdvertData((prev) => ({ ...prev, description: e.target.value }))
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Price"
                                                        value={editingAdvertData.asking_price}
                                                        onChange={(e) =>
                                                            setEditingAdvertData((prev) => ({ ...prev, asking_price: e.target.value }))
                                                        }
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-2">
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Age"
                                                        value={editingAdvertData.pet_age}
                                                        onChange={(e) =>
                                                            setEditingAdvertData((prev) => ({ ...prev, pet_age: e.target.value }))
                                                        }
                                                    />
                                                </Form.Group>
                                                <Button variant="success" size="sm" onClick={() => saveEditing(advert.id)} className="me-2">
                                                    Save
                                                </Button>
                                                <Button variant="secondary" size="sm" onClick={cancelEditing}>
                                                    Cancel
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Card.Title>{advert.title}</Card.Title>
                                                <Card.Text>
                                                    {advert.pet_type} - {advert.pet_breed}
                                                    <br />
                                                    Price: {advert.asking_price}
                                                    <br />
                                                    {advert.pet_age && <>Age: {advert.pet_age}</>}
                                                </Card.Text>
                                                <Button variant="secondary" size="sm" className="me-2" onClick={() => startEditing(advert)}>
                                                    Edit
                                                </Button>
                                                <Button variant="danger" size="sm" onClick={() => deleteAdvert(advert.id)}>
                                                    Delete
                                                </Button>
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Col>

                {/* Right Column: Create Advert Form / Under Review Message */}
                <Col md={4} className="bg-dark mt-4 mb-4 p-4 text-light rounded">
                    {/*
    STEP 1:
    If user has fewer than 2 ads & location is allowed, show the "Create Ad" form.
    Otherwise, show a warning that they've exceeded the free limit.
  */}
                    {step === 1 && locationAllowed && userAdverts.length < 2 && (
                        <Form onSubmit={handleSubmitStep1}>
                            <h3 className="text-light">{t("Create Rehome / Sell Ad")}</h3>
                            <hr />
                            <Form.Group className="mb-3" controlId="formTitle">
                                <Form.Label>{t("Title")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter title"
                                    name="title"
                                    value={advertData.title}
                                    onChange={handleInputChange}
                                    maxLength={200} // Roughly equivalent to 200 words
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formDescription">
                                <Form.Label>{t("Description")}</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    placeholder="Full disclosure of pet vaccination, heatlh and training can assist you in finding a new home for your favourite pet and will make the transition easier."
                                    name="description"
                                    value={advertData.description}
                                    onChange={handleInputChange}
                                    maxLength={1200} // Roughly equivalent to 200 words
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPetAge">
                                <Form.Label>Pet Age (optional)</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter pet age"
                                    name="pet_age"
                                    value={advertData.pet_age}
                                    onChange={handleInputChange}
                                    maxLength={50} // Roughly equivalent to 200 words
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formAskingPrice">
                                <Form.Label>{t("Asking Price")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter asking price"
                                    name="asking_price"
                                    value={advertData.asking_price}
                                    onChange={handleInputChange}
                                    maxLength={5} // Roughly equivalent to 200 words
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPetType">
                                <Form.Label>{t("Pet Type")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="pet_type"
                                    value={advertData.pet_type}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Pet Type</option>
                                    {petTypes.map((type, index) => (
                                        <option key={index} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formPetBreed">
                                <Form.Label>{t("Pet Breed")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="pet_breed"
                                    value={advertData.pet_breed}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Pet Breed</option>
                                    {breedTypes.map((breed, index) => (
                                        <option key={index} value={breed}>
                                            {breed}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            {/* Display any form validation errors */}
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                            <Button variant="primary" type="submit">
                                {t("Continue to Upload Images")}
                            </Button>
                        </Form>
                    )}

                    {/*
    If the user already has 2 or more free ads, show a warning
    asking them to download the app instead.
  */}
                    {step === 1 && locationAllowed && userAdverts.length >= 2 && (
                        <Alert variant="warning" className="text-dark">
                            <h4>{t("You’ve Reached Your Free Ad Limit")}</h4>
                            <p>
                                {t(" You already have two active ads. To create additional ads and take advantage of real‑time chat with potential adopters, please download our Pets Home App.")}
                            </p>
                            <p>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.app.petshome"
                                    target="_blank"
                                    rel="noopener noreferrer"

                                    className='me-3 text-dark mr-4'
                                >
                                    Download for Android
                                </a>
                                <a
                                    href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className='text-dark'
                                >
                                    Download for iOS
                                </a>
                            </p>
                        </Alert>
                    )}

                    {/*
    STEP 2: Image Upload Handling
    Only show if step === 2
  */}
                    {step === 2 && (
                        <>
                            <Alert variant="success">Now upload images.</Alert>
                            <Form onSubmit={handleSubmitImages}>
                                <Form.Group controlId="formMainImage" className="mb-3">
                                    <Form.Label>Main Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                            setImageFiles({ ...imageFiles, main_image: e.target.files[0] })
                                        }
                                    />
                                    {uploadProgress.main_image > 0 && uploadProgress.main_image < 100 && (
                                        <ProgressBar
                                            now={uploadProgress.main_image}
                                            label={`${uploadProgress.main_image}%`}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group controlId="formImage1" className="mb-3">
                                    <Form.Label>Image 1</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                            setImageFiles({ ...imageFiles, image_1: e.target.files[0] })
                                        }
                                    />
                                    {uploadProgress.image_1 > 0 && uploadProgress.image_1 < 100 && (
                                        <ProgressBar
                                            now={uploadProgress.image_1}
                                            label={`${uploadProgress.image_1}%`}
                                        />
                                    )}
                                </Form.Group>
                                <Form.Group controlId="formImage2" className="mb-3">
                                    <Form.Label>Image 2</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                            setImageFiles({ ...imageFiles, image_2: e.target.files[0] })
                                        }
                                    />
                                    {uploadProgress.image_2 > 0 && uploadProgress.image_2 < 100 && (
                                        <ProgressBar
                                            now={uploadProgress.image_2}
                                            label={`${uploadProgress.image_2}%`}
                                        />
                                    )}
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    {t('Upload Images')}
                                </Button>
                            </Form>

                            {advertData.images.main_image && (
                                <Card className="mt-4">
                                    <Card.Img variant="top" src={advertData.images.main_image} alt="Main" />
                                    <Card.Body>
                                        <Card.Title>{advertData.title}</Card.Title>
                                        <Card.Text>{advertData.description}</Card.Text>
                                        <Row>
                                            {advertData.images.image_1 && (
                                                <Col xs={4}>
                                                    <Card.Img src={advertData.images.image_1} alt="Image 1" />
                                                </Col>
                                            )}
                                            {advertData.images.image_2 && (
                                                <Col xs={4}>
                                                    <Card.Img src={advertData.images.image_2} alt="Image 2" />
                                                </Col>
                                            )}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            )}
                        </>
                    )}

                    {/*
    STEP 3: Ad Under Review
  */}
                    {step === 3 && (
                        <Card className="mt-4 text-dark">
                            <Card.Body>
                                <Card.Title>{t('Your ad is under review')}</Card.Title>
                                <Card.Text>
                                    {t('Your ad is under review and will be published shortly. Please download our app on iOS or Android to see messages and join real‑time chat.')}
                                </Card.Text>
                                <div className="d-flex justify-content-around">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.app.petshome"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/google-play-badge.svg"
                                            alt="Google Play"
                                            style={{ width: '120px' }}
                                        />
                                    </a>
                                    <a
                                        href="https://apps.apple.com/us/app/pets-home-app/id1549828591"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/app-store-badge.svg"
                                            alt="App Store"
                                            style={{ width: '120px' }}
                                        />
                                    </a>
                                </div>
                                <Button variant="primary" className="mt-3" onClick={handleResetForm}>
                                    {t('Create New Ad')}
                                </Button>
                            </Card.Body>
                        </Card>
                    )}
                </Col>

            </Row>
        </Container>
    );
}

export default CreateAdvert;
